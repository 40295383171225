body {
  font-family: Verdana, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

a:focus,
button:focus {
  outline: 1px solid orange;
}