body {
  font-family: Verdana, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

a:focus,
button:focus {
  outline: 1px solid orange;
}
.detail-meta__dt {
  display: inline-block;
  width: 230px;
  font-weight: bold;
}

.detail-meta__dd {
  display: inline-block;
  width: calc(100% - 230px);
}

.detail-meta__dd:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .detail-meta__dt {
    display: block;
    width: 100%;
    font-weight: bold;
  }
  
  .detail-meta__dd {
    display: block;
    width: 100%;
  }
}
